import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        redirect:'/management',
        component: () => import('../views/index.vue')
    },
    {
        path: '/index',
        name: 'index2',
        component: () => import('../views/index.vue')
    },
    {
        path: '/sale',
        name: 'sale',
        component: () => import('../views/sale.vue')
    }, {
        path: '/parking',
        name: 'parking',
        component: () => import('../views/parking.vue')
    },
    {
        path: '/sale/ticketData',
        name: 'ticketData',
        component: () => import('../views/ticketData.vue')
    }, {
        path: '/sale/ticketRefund',
        name: 'ticketRefund',
        component: () => import('../views/ticketRefund.vue')
    }, {
        path: '/sale/secondSale',
        name: 'secondSale',
        component: () => import('../views/secondSale.vue')
    }, {
        path: '/sale/cardSale',
        name: 'cardSale',
        component: () => import('../views/cardSale.vue')
    }, {
        path: '/sale/shopSale',
        name: 'shopSale',
        component: () => import('../views/shopSale.vue')
    },
    {
        path: '/tourist',
        name: 'tourist',
        component: () => import('../views/tourist.vue')
    }, {
        path: '/management',
        name: 'management',
        component: () => import('../views/management.vue')
    }, {
        path: '/nightTour',
        name: 'nightTour',
        component: () => import('../views/nightTour.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/test.vue')
    },
]
const router = new VueRouter({
    // mode: "history",
    routes
})

export default router
