<template>
    <div id="app">
        <div id="index" ref="appRef">
            <div class="a_header">
                <div class="header_m">
                    <div :class="{ active: actvited == 5 }" @click="goPage(5)">
                        日报数据
                    </div>
                    <div :class="{ active: actvited == 1 }" @click="goPage(1)">
                        实时数据
                    </div>
                    <div :class="{ active: actvited == 2 }" @click="goPage(2)">
                        销售数据
                    </div>
                    <div :class="{ active: actvited == 3 }" @click="goPage(3)">
                        游客数据
                    </div>
                    <div :class="{ active: actvited == 4 }" @click="goPage(4)">
                        停车数据
                    </div>

                </div>
                <div class="header_r">
                    <div class="date_box">
                        <span class="one"> {{ dateYear }} </span>
                        <span class="two"> {{ dateWeek }} </span>
                        <span class="three"> {{ dateDay }} </span>
                        <img class="wea_img" :src="wea_img" alt=""/>
                        <span>{{ wen }}℃</span>
                    </div>
                </div>
            </div>
            <div class="page">
                <router-view/>
            </div>
        </div>
    </div>
</template>
<script>
    import {getWeather} from "./api";
    import {formatTime} from "./utils/index.js";
    import drawMixin from "./utils/drawMixin";

    export default {
        mixins: [drawMixin],
        components: {},
        props: {},
        data() {
            return {
                timing: null,
                actvited: 5,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: [
                    "星期日",
                    "星期一",
                    "星期二",
                    "星期三",
                    "星期四",
                    "星期五",
                    "星期六",
                ],
                wea_img: "", // 天气图标
                wen: "", //温度
            };
        },
        computed: {},
        created() {
            // console.log(window.location.href.split('Token=')[1])
            if (window.location.href.split('Token=')[1] && window.location.href.split('Token=')[1] != window.localStorage.getItem('screenToken')) {
                window.localStorage.setItem('screenToken', window.location.href.split('Token=')[1])
            }
            this.getWeathered();
            this.timeFn();
        },
        mounted() {
        },
        beforeDestroy() {
            clearInterval(this.timing);
        },
        watch: {
            $route(to) {
                let path = to.fullPath;
                if (path.indexOf("sale") > 0) {
                    this.actvited = 2;
                } else if (path.indexOf("tourist") > 0) {
                    this.actvited = 3;
                }else if (path.indexOf("parking") > 0) {
                    this.actvited = 4;
                }else if (path.indexOf("management") > 0 || path.indexOf("nightTour") > 0) {
                    this.actvited = 5;
                } else {
                    this.actvited = 1;
                }
            },
        },
        methods: {
            // 获取天气
            getWeathered() {
                getWeather().then((res) => {
                    this.wen = res.data.wen;
                    this.wea_img = res.data.wea_img;
                });
            },
            // 时间过滤
            timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), "HH: mm: ss");
                    let dateYear = formatTime(new Date(), "yyyy-MM-dd").split("-");
                    this.dateYear = `${dateYear[0]}年${dateYear[1]}月${dateYear[2]}日`;
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000);
            },
            goPage(val) {
                this.actvited = val;
                switch (val) {
                    case 1:
                        this.$router.push("/index");
                        break;
                    case 2:
                        this.$router.push("/sale");
                        break;
                    case 3:
                        this.$router.push("/tourist");
                        break;
                    case 4:
                        this.$router.push("/parking");
                        break;
                    case 5:
                        this.$router.push("/management");
                        break;
                }
            },
        },
    };
</script>
<style lang="scss">
    @import "./assets/scss/index.scss";
</style>
<style lang="scss" scoped>
    #app {
        background-color: #01112e;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
    }

    #index {
        position: relative;
        width: 1920px;
        height: 1080px;
        background: url("./assets/img/1-bg1@2x.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;

        .a_header {
            position: absolute;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 58px;

            .header_m {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 580px;

                > div {
                    background: url("./assets/img/1-nav-normal@2x.png") no-repeat;
                    background-size: 100% 100%;
                    font-size: 20px;
                    text-align: center;
                    padding: 10px 50px;
                    cursor: pointer;
                }

                .active {
                    position: relative;
                    background: url("./assets/img/1-nav-choose@2x.png") no-repeat;
                    background-size: 100% 100%;
                    color: #ffbb00;
                }

                .active::before,
                .active::after {
                    content: "";
                    position: absolute;
                    left: 37px;
                    top: 13px;
                    width: 7px;
                    height: 11px;
                    background: url("./assets/img/1-nav-choose-arrow-left@2x.png") no-repeat;
                    background-size: 100% 100%;
                }

                .active::after {
                    left: 135px;
                    background: url("./assets/img/1-nav-choose-arrow-left@2x.png") no-repeat;
                    background-size: 100% 100%;
                    transform: rotate(180deg);
                }
            }

            .header_r {
                height: 40px;
                padding-right: 48px;
                font-size: 16px;

                .date_box {
                    display: flex;
                    align-items: center;
                    line-height: 40px;

                    .two {
                        margin: 0 10px;
                    }

                    .wea_img {
                        display: block;
                        width: 24px;
                        height: 24px;
                        margin: 0 8px 0 32px;
                    }
                }
            }
        }

        .page {
            width: 1920px;
            height: 100%;
            padding: 135px 39px 75px;
        }
    }
</style>
