import axios from 'axios'
import qs from 'querystring'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 20000,
    transformRequest: [(data) => {
        return qs.stringify(data)
    }]
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['x-requested-with'] = 'XMLHttpRequest'
        config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded' // 配置请求头
        config.headers['x-token'] = window.localStorage.getItem('screenToken')
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.code === 1) {
            // Message({
            //     message: res.msg || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.code === 0) {
            return res
        } else {
            // window.location.replace('http://cswl_pc.szwawo.com')
            window.location.replace('https://cswl.szysshly.com')
            // window.open('https://cswl.szysshly.com')
            // window.location.href('https://cswl.szysshly.com')
            // removeToken()
            // this.$router.push('/login')
        }
    },
    error => {
        // Message({
        //   message: error.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        // Message('网络请求超时！')
        return Promise.reject(error)
    }
)

export default service
