import request from '@/utils/request'
// 获取天气
export function getWeather(params) {
    return request({
        url: '/admin/tj_weather',
        method: 'get',
        params
    })
}
// 数据总览-今日数据
export function getOverview(params) {
    return request({
        url: '/admin/tj_overview_data',
        method: 'get',
        params
    })
}
// 数据总览-销量top5
export function getTop5(params) {
    return request({
        url: '/admin/tj_overview_top5',
        method: 'get',
        params
    })
}
// 数据总览-停车场数据
export function getPark(params) {
    return request({
        url: '/admin/tj_overview_park',
        method: 'get',
        params
    })
}
// 数据总览-地图
export function getMap(params) {
    return request({
        url: '/admin/tj_overview_map',
        method: 'get',
        params
    })
}
// 数据总览-销售额
export function getSale(params) {
    return request({
        url: '/admin/tj_overview_sale',
        method: 'get',
        params
    })
}

// 游客总览-每日游客总量
export function touristList(params) {
    return request({
        url: '/admin/tj_tourist_list',
        method: 'get',
        params
    })
}
// 游客总览-游客时间段列表
export function touristTimeList(params) {
    return request({
        url: '/admin/tj_tourist_time_list',
        method: 'get',
        params
    })
}
// 游客总览-入园方式
export function touristTypeData(params) {
    return request({
        url: '/admin/tj_tourist_type_data',
        method: 'get',
        params
    })
}
// 游客总览-游客类型列表
export function touristTypeList(params) {
    return request({
        url: '/admin/tj_tourist_type_list',
        method: 'get',
        params
    })
}
// 游客总览-游客来源
export function touristSourceList(params) {
    return request({
        url: '/admin/tj_tourist_source_list',
        method: 'get',
        params
    })
}

// // 景区/酒店列表
// export function getBusinessList(data) {
//     return request({
//         url: '/screen/business_list',
//         method: 'post',
//         data
//     })
// }

// 销售数据-年度/月份/今日
export function tjSaleTotal(params) {
    return request({
        url: '/admin/tj_sale_total',
        method: 'get',
        params
    })
}
// 销售数据-月热销排行
export function tjSaleMonthRank(params) {
    return request({
        url: '/admin/tj_sale_month_rank',
        method: 'get',
        params
    })
}
// 销售数据-月销售数据
export function tjSaleMonthData(params) {
    return request({
        url: '/admin/tj_sale_month_data',
        method: 'get',
        params
    })
}
// 销售数据-分类数据
export function tjSaleTypeData(params) {
    return request({
        url: '/admin/tj_sale_type_data',
        method: 'get',
        params
    })
}
// 销售数据-景区数据
export function tjSaleScenicSpotData(params) {
    return request({
        url: '/admin/tj_sale_scenic_spot_data',
        method: 'get',
        params
    })
}

// 销售数据-景区数据
export function tjScenicSpotList(params) {
    return request({
        url: '/admin/tj_scenic_spot_list',
        method: 'get',
        params
    })
}
// 销售数据-分类数据详情
export function tjSaleTypeDetail(params) {
    return request({
        url: '/admin/tj_sale_type_detail',
        method: 'get',
        params
    })
}
// 商城商户列表
export function tjMallShopList(params) {
    return request({
        url: '/admin/tj_mall_shop_list',
        method: 'get',
        params
    })
}
// 商城分类列表
export function tjMallTypeList(params) {
    return request({
        url: '/admin/tj_mall_type_list',
        method: 'get',
        params
    })
}

// 商城分类列表
export function tjHolidayList(params) {
    return request({
        url: '/admin/tj_holiday_list',
        method: 'get',
        params
    })
}
// 停车数据-客源地（省内）
export function tjParkCity(params) {
    return request({
        url: '/admin/tj_park_city',
        method: 'get',
        params
    })
}
// 停车数据-客源地（省外）
export function tjParkProvince(params) {
    return request({
        url: '/admin/tj_park_province',
        method: 'get',
        params
    })
}
// 停车数据-车辆进出分析
export function tjParkAccessAnalysis(params) {
    return request({
        url: '/admin/tj_park_access_analysis',
        method: 'get',
        params
    })
}
// 停车数据-停车时长分析
export function tjParkDurationAnalysis(params) {
    return request({
        url: '/admin/tj_park_duration_analysis',
        method: 'get',
        params
    })
}
// 数据总览-停车营收
export function tjOverviewParkIncome(params) {
    return request({
        url: '/admin/tj_overview_park_income',
        method: 'get',
        params
    })
}

// 门票销售数据-各景区销售额
export function tjScenicSaleList(params) {
    return request({
        url: '/admin/tj_scenic_sale_list',
        method: 'get',
        params
    })
}
// 门票销售数据-销售渠道分布
export function tjScenicChannelOrders(params) {
    return request({
        url: '/admin/tj_scenic_channel_orders',
        method: 'get',
        params
    })
}
// 门票销售数据-退票数据
export function tjScenicRefundList(params) {
    return request({
        url: '/admin/tj_scenic_refund_list',
        method: 'get',
        params
    })
}
// 门票销售数据-近30天销售
export function tjScenicDateSaleList(params) {
    return request({
        url: '/admin/tj_scenic_date_sale_list',
        method: 'get',
        params
    })
}
// 门票销售数据-近30天销售
export function tjParkList(params) {
    return request({
        url: '/admin/tj_park_list',
        method: 'get',
        params
    })
}

// 经营日报-接待人次-入园方式
export function tjReportTouristType(params) {
    return request({
        url: '/admin/tj_report_tourist_type',
        method: 'get',
        params
    })
}
// 经营日报-接待人次-入园人次
export function tjReportTourist(params) {
    return request({
        url: '/admin/tj_report_tourist',
        method: 'get',
        params
    })
}

// 经营日报-经营收入-门票项目占比
export function tjReportIncomeType(params) {
    return request({
        url: '/admin/tj_report_income_type',
        method: 'get',
        params
    })
}

// 经营日报-经营收入-门票项目占比
export function tjReportIncome(params) {
    return request({
        url: '/admin/tj_report_income',
        method: 'get',
        params
    })
}
// 经营日报-经营收入-人均消费
export function tjReportIncomePer(params) {
    return request({
        url: '/admin/tj_report_income_per',
        method: 'get',
        params
    })
}

// 经营日报-团队情况-团散占比
export function tjReportTeamType(params) {
    return request({
        url: '/admin/tj_report_team_type',
        method: 'get',
        params
    })
}
// 经营日报-团队情况-团散占比
export function tjReportTeamNum(params) {
    return request({
        url: '/admin/tj_report_team_num',
        method: 'get',
        params
    })
}



// 经营日报-夜游情况-夜游人次
export function tjReportNightTourist(params) {
    return request({
        url: '/admin/tj_report_night_tourist',
        method: 'get',
        params
    })
}
// 经营日报-夜游情况-门票项目占比
export function tjReportNightIncomeType(params) {
    return request({
        url: '/admin/tj_report_night_income_type',
        method: 'get',
        params
    })
}
// 经营日报-夜游情况-夜游收入
export function tjReportNightIncome(params) {
    return request({
        url: '/admin/tj_report_night_income',
        method: 'get',
        params
    })
}
// 经营日报-夜游情况-人均消费
export function tjReportNightIncomePer(params) {
    return request({
        url: '/admin/tj_report_night_income_per',
        method: 'get',
        params
    })
}




